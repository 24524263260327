import * as React from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";


export default function WorkspaceMenu({ workspace, refetch }) {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(x => !x)}>
            <DropdownToggle caret color="primary">
                {false && <FontAwesomeIcon icon={faBars} />}{" "}
                Actions
            </DropdownToggle>
            <DropdownMenu end>

                {workspace.rel.isMember
                ? <WorkspaceLeaveItem workspace={workspace} onComplete={refetch} />
                : <WorkspaceJoinItem workspace={workspace} onComplete={refetch} />}

                <DropdownItem divider />

                <DropdownItem
                    disabled={!workspace.authz.write}
                    tag={Link}
                    to={`/workspace/${workspace.id}/settings`}
                >
                    Workspace settings
                </DropdownItem>

                <DropdownItem
                    disabled={!workspace.authz.write}
                    tag={Link}
                    to={`/workspace/${workspace.id}/settings/options`}
                >
                    Manage options
                </DropdownItem>

                <DropdownItem
                    disabled={!workspace.authz.write}
                    tag={Link}
                    to={`/workspace/${workspace.id}/settings/items`}
                >
                    Manage voting items
                </DropdownItem>

                <DropdownItem
                    disabled={!workspace.authz.write}
                    tag={Link}
                    to={`/workspace/${workspace.id}/settings/users`}
                >
                    Manage users
                </DropdownItem>

            </DropdownMenu>
        </Dropdown>
    );
}


function WorkspaceJoinItem({ workspace, onComplete }) {
    const [doMutation] = useMutation(gql`
        mutation joinWorkspace ($workspaceId: String!) {
            joinWorkspace(workspaceId: $workspaceId) {
                ok
                errorMessage
            }
        }
    `);

    const onClick = () => {
        doMutation({
            variables: { workspaceId: workspace.id }
        }).then(({ data }) => {
            if (data?.joinWorkspace?.ok) {
                if (onComplete) {
                    onComplete();
                }
            }
        })
    };

    return (
        <DropdownItem disabled={!workspace.authz.join} onClick={onClick}>
            Join
        </DropdownItem>
    );
}


function WorkspaceLeaveItem({ workspace, onComplete }) {
    const [doMutation] = useMutation(gql`
        mutation leaveWorkspace ($workspaceId: String!) {
            leaveWorkspace(workspaceId: $workspaceId) {
                ok
                errorMessage
            }
        }
    `);

    const onClick = () => {
        doMutation({
            variables: { workspaceId: workspace.id }
        }).then(({ data }) => {
            if (data?.leaveWorkspace?.ok) {
                if (onComplete) {
                    onComplete();
                }
            }
        })
    };

    return (
        <DropdownItem disabled={!workspace.authz.leave} onClick={onClick}>
            Leave
        </DropdownItem>
    );
}
