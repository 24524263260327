import * as React from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import { useMutation, gql } from "@apollo/client";
import useGetWorkspace from "./use-get-workspace";


export default function WorkspaceSettingsGeneralPage() {
    const { workspaceId } = useParams();
    const { data, error, loading, refetch } =
        useGetWorkspace(workspaceId, { fetchPolicy: "network-only" });

    if (error) {
        return <div>Error</div>;
    }

    if (loading || !data?.workspace) {
        return <div>Loading...</div>;
    }

    const onSuccess = () => refetch();
    return <WorkspaceSettingsForm
               workspace={data.workspace}
               onSuccess={onSuccess} />;
}


function WorkspaceSettingsForm({ workspace, onSuccess }) {
    const [formData, setFormData] = React.useState({
        label: workspace.label,
    });

    const [doUpdateWorkspace] = useMutation(gql`
        mutation updateWorkspace (
            $workspaceId: String!,
            $data: UpdateWorkspaceInput!
        ) {
            updateWorkspace (workspaceId: $workspaceId, data: $data) {
                ok
                errorMessage
            }
        }
    `);

    const submit = () => {
        doUpdateWorkspace({
            variables: {
                workspaceId: workspace.id,
                data: formData,
            }
        }).then(result => {
            if (result?.data?.updateWorkspace?.ok) {
                onSuccess();
            }
        });
    };

    const onFormSubmit = evt => {
        evt.preventDefault();
        submit();
    };

    return (
        <Form onSubmit={onFormSubmit}>
            <FormGroup>
                <Label for="edit-workspace-label">
                    Title:
                </Label>
                <Input
                    id="edit-workspace-label"
                    name="full_name"
                    value={formData.label}
                    onChange={evt =>
                        setFormData(data =>
                            ({ ...data, label: evt.target.value }))}
                />
            </FormGroup>
            <div className="text-end">
                <Button type="submit" color="primary">Save settings</Button>
            </div>
        </Form>
    );
}
