import * as React from "react";
import Layout from "app/ui/layout";


export default function PageNotFound() {
    return (
        <Layout>
            Page Not Found
        </Layout>
    );
}
