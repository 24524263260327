import * as React from "react";
import { useParams, useNavigate, NavLink as RNavLink, Outlet } from "react-router-dom";
import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import { useMutation, gql } from "@apollo/client";
import useGetWorkspace from "./use-get-workspace";


export default function AppendItemButton({
    workspaceId,
    onSuccess,
    text,
    outline,
    color,
    ...props
}) {
    const [isOpen, setOpen] = React.useState(false);
    return (
        <>
            <Button
                outline={outline}
                color={color}
                 {...props}
                onClick={() => setOpen(true)}
            >
                {text}
            </Button>
            <NewItemModal
                isOpen={isOpen}
                dismiss={() => setOpen(false)}
                workspaceId={workspaceId}
                onSuccess={() => {
                    setOpen(false);
                    onSuccess && onSuccess();
                }}
            />
        </>
    );
}

AppendItemButton.defaultProps = {
    text: "Add another item",
    color: "secondary",
    outline: true,
};


function NewItemModal({ isOpen, ...props }) {
    const { dismiss } = props;
    if (!isOpen) {
        return null;
    }
    return (
        <Modal isOpen={isOpen} toggle={() => dismiss()} size="lg">
            <ModalHeader toggle={() => dismiss()}>
                Add voting item
            </ModalHeader>
            <ModalBody>
                <NewItemFormWrapper {...props} />
            </ModalBody>
        </Modal>
    );
}


function NewItemFormWrapper(props) {
    const { workspaceId } = props;
    const { data, loading, error } =
        useGetWorkspace(workspaceId, { fetchPolicy: "network-only" });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data?.workspace) {
        return <div>Not found</div>;
    }

    let { workspace } = data;
    console.log("Workspace", workspace);

    return (
        <NewItemForm {...props} workspace={workspace} />
    );
}


function NewItemForm({ workspace, workspaceId, onSuccess, dismiss }) {
    const [name, setName] = React.useState("");
    const [doUpdateWorkspace] = useMutation(gql`
        mutation updateWorkspace (
            $workspaceId: String!,
            $data: UpdateWorkspaceInput!
        ) {
            updateWorkspace (workspaceId: $workspaceId, data: $data) {
                ok
                errorMessage
            }
        }
    `);

    const onFormSubmit = (evt) => {
        evt.preventDefault();

        // Remove extraneous keys
        let votingItems = workspace
            .votingItems
            .map(({ id, name, description, status }) =>
                ({ id, name, description, status }));

        let newItems = [ ...votingItems, { name, description: "", status: "FUTURE" } ];

        doUpdateWorkspace({
            variables: {
                workspaceId: workspace.id,
                data: { items: newItems },
            }
        }).then(result => {
            if (result?.data?.updateWorkspace?.ok) {
                onSuccess();
            }
        });
    };
    let isValid = !!name.trim();

    return (
        <Form onSubmit={onFormSubmit}>
            <FormGroup>
                <Label for="name">
                    New item name:
                </Label>
                <Input
                    id="name"
                    name="name"
                    value={name}
                    onChange={evt => setName(evt.target.value)} />
            </FormGroup>
            <div className="d-flex justify-content-between">
                {dismiss && <Button onClick={() => dismiss()} outline>
                    Cancel
                </Button>}
                <Button color="primary" type="submit" disabled={!isValid}>
                    Create new item
                </Button>
            </div>
        </Form>
    );
}
