import * as React from "react";
import { Alert, Button } from "reactstrap";
import { useQuery, useMutation, gql } from "@apollo/client";

const SHOW_LEAVE_INTERFACE = false;


export default function JoinWorkspaceSection({ workspace, refetch }) {
    if (workspace.rel.isMember) {
        if (SHOW_LEAVE_INTERFACE) {
            return (
                <Alert color="primary">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            You are a member of this workspace
                        </div>
                        <div>
                            {workspace.authz.leave &&
                             (<LeaveWorkspaceButton
                                  workspaceId={workspace.id}
                                  onComplete={refetch} />)}
                        </div>
                    </div>
                </Alert>
            );
        }
        return null;
    }

    // TODO: if not logged in, we also want to do that before joining!

    if (workspace.authz.join) {
        return (
            <Alert color="primary">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        Join this workspace to vote
                    </div>
                    <div>
                        <JoinWorkspaceButton
                            workspaceId={workspace.id}
                            onComplete={refetch} />
                    </div>
                </div>
            </Alert>
        );
    }

    return null;
}


function JoinWorkspaceButton({ workspaceId, onComplete }) {
    const [doMutation, mutationState] = useMutation(gql`
        mutation joinWorkspace ($workspaceId: String!) {
            joinWorkspace(workspaceId: $workspaceId) {
                ok
                errorMessage
            }
        }
    `);

    const onClick = () => {
        doMutation({
            variables: { workspaceId }
        }).then(({ data }) => {
            if (data?.joinWorkspace?.ok) {
                if (onComplete) {
                    onComplete();
                }
            }
        })
    };

    return (
        <Button
            color="primary"
            onClick={onClick}
            disabled={mutationState.loading}
        >
            Join
        </Button>
    );
}


function LeaveWorkspaceButton({ workspaceId, onComplete }) {
    const [doMutation, mutationState] = useMutation(gql`
        mutation leaveWorkspace ($workspaceId: String!) {
            leaveWorkspace(workspaceId: $workspaceId) {
                ok
                errorMessage
            }
        }
    `);

    const onClick = () => {
        doMutation({
            variables: { workspaceId }
        }).then(({ data }) => {
            if (data?.leaveWorkspace?.ok) {
                if (onComplete) {
                    onComplete();
                }
            }
        })
    };

    return (
        <Button
            color="primary"
            onClick={onClick}
            disabled={mutationState.loading}
        >
            Leave
        </Button>
    );
}
