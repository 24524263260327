import { ApolloProvider } from "@apollo/client";
import { useAccessToken } from "app/storage/access-token";
import { createApolloClient } from "./graphql";


export default function CustomApolloProvider(props) {
    let accessToken = useAccessToken();
    // TODO: do we need to use React.useMemo() or similar to cache this?
    let client = createApolloClient({ accessToken });
    console.log("Creating apollo client with token", accessToken);
    return <ApolloProvider client={client} {...props} />
};
