import * as React from "react";
import { useParams, useNavigate, NavLink as RNavLink, Outlet } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";


export default function WorkspaceSettingsPage() {
    return (
        <WorkspaceSettingsModal>
            <Outlet />
        </WorkspaceSettingsModal>
    );
}


function WorkspaceSettingsModal({ children }) {
    const { workspaceId } = useParams();
    const navigate = useNavigate();
    const dismiss = () => navigate(`/workspace/${workspaceId}`);
    return (
        <Modal isOpen toggle={() => dismiss()} size="lg">
            <ModalHeader toggle={() => dismiss()} className="border-bottom-0">
                Workspace settings
            </ModalHeader>
            <div>
                <WorkspaceSettingsTabs workspaceId={workspaceId} />
            </div>
            <ModalBody>
                <div>
                    {children}
                </div>
            </ModalBody>
        </Modal>
    );
}


function WorkspaceSettingsTabs({ workspaceId }) {
    return (
        <Nav tabs className="px-3">
            <NavItem>
                <NavLink tag={RNavLink} end to={`/workspace/${workspaceId}/settings`}>
                    General
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={RNavLink} end to={`/workspace/${workspaceId}/settings/options`}>
                    Options
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={RNavLink} end to={`/workspace/${workspaceId}/settings/items`}>
                    Items
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={RNavLink} end to={`/workspace/${workspaceId}/settings/users`}>
                    Users
                </NavLink>
            </NavItem>
        </Nav>
    );
}
