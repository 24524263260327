const DEFAULT_LOCALE = "en-US";


export function formatNumber(number, options={}) {
    return new Intl.NumberFormat(DEFAULT_LOCALE, options).format(number);
}


export function formatCurrency(value, currency = "USD") {
    return new Intl.NumberFormat(DEFAULT_LOCALE, {
        style: "currency",
        currency,
    }).format(value);
}


export function formatPercent(value, options) {
    return new Intl.NumberFormat(DEFAULT_LOCALE, {
        style: "percent",
        maximumFractionDigits: 1,
        ...options,
    }).format(value);
}
