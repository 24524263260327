import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Button,
    Input,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { useMutation, gql } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import useGetWorkspace from "./use-get-workspace";


const OPTION_PRESETS = [
    {
        label: "Yes / No",
        options: [
            { id: "yes", label: "Yes" },
            { id: "no", label: "No" },
        ]
    },
    {
        label: "Star rating",
        options: [
            { id: "1", label: "⭐" },
            { id: "2", label: "⭐⭐" },
            { id: "3", label: "⭐⭐⭐" },
            { id: "4", label: "⭐⭐⭐⭐" },
            { id: "5", label: "⭐⭐⭐⭐⭐" },
        ]
    },
    {
        label: "Fibonacci",
        options:
            ["1", "2", "3", "5", "8", "13", "21", "34"]
                .map(id => ({ id, label: id })),

    },
];


export default function WorkspaceSettingsOptionsPage() {
    const { workspaceId } = useParams();
    const { data, error, loading, refetch } =
        useGetWorkspace(workspaceId, { fetchPolicy: "network-only" });

    if (error) {
        return <div>Error</div>;
    }

    if (loading || !data?.workspace) {
        return <div>Loading...</div>;
    }

    const onSuccess = () => refetch();
    return <WorkspaceOptionsSettingsForm
        workspace={data.workspace}
        onSuccess={onSuccess} />;
}


function WorkspaceOptionsSettingsForm({ workspace, onSuccess }) {
    const [isChanged, setChanged] = React.useState(false);
    const [options, _setOptions] = React.useState(workspace.options || []);

    const setOptions = value => {
        setChanged(true);
        _setOptions(value);
    };

    const [doUpdateWorkspace, { loading }] = useMutation(gql`
        mutation updateWorkspace (
            $workspaceId: String!,
            $data: UpdateWorkspaceInput!
        ) {
            updateWorkspace (workspaceId: $workspaceId, data: $data) {
                ok
                errorMessage
            }
        }
    `);

    const submit = () => {
        doUpdateWorkspace({
            variables: {
                workspaceId: workspace.id,
                data: {
                    options: options
                        .filter(x => !!x.id)
                        .map(({ id, label }) => ({ id, label })),
                },
            }
        }).then(result => {
            if (result?.data?.updateWorkspace?.ok) {
                setChanged(false);
                onSuccess();
            }
        });
    };

    const onFormSubmit = () => {
        submit();
    };

    const addOption = value =>
        setOptions(options => [...options, value]);

    const updateOption = (idx, value) =>
        setOptions(options =>
            [
                ...options.slice(0, idx),
                { ...options[idx], ...value },
                ...options.slice(idx + 1),
            ]);

    const removeOption = (idx) =>
        setOptions(options =>
            [
                ...options.slice(0, idx),
                ...options.slice(idx + 1),
            ]);

    return (
        <div>

            {options.length === 0 && (
                <div className="text-center text-muted my-5">
                    No options yet. Add some, or try a preset.
                </div>
            )}

            {options.map((option, optionIdx) => (
                <div key={optionIdx} className="my-2 d-flex">
                    <div className="me-2 flex-grow-1">
                        <Input
                            placeholder="value"
                            value={option.id || ""}
                            onChange={event =>
                                updateOption(optionIdx, { id: event.target.value})}
                        />
                    </div>
                    <div className="me-2 flex-grow-1">
                        <Input
                            placeholder="label"
                            value={option.label || ""}
                            onChange={event =>
                                updateOption(optionIdx, { label: event.target.value})}
                        />
                    </div>
                    <Button
                        onClick={() => removeOption(optionIdx)}
                        color="link"
                        className="text-dark"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </div>
            ))}

            <div className="mt-4 d-flex justify-content-between">
                <div className="me-1">
                    <Button color="success" onClick={() => addOption({ id: "", label: "" })}>
                        Add option
                    </Button>
                </div>
                <div className="me-1">
                    <PresetsDropdown
                        presets={OPTION_PRESETS}
                        onChoose={options => setOptions(options)} />
                </div>
                <div className="flex-grow-1" />
                <div>
                    <Button
                        color={!isChanged ? "secondary" : "primary"}
                        onClick={() => onFormSubmit()}
                        disabled={!isChanged || loading}
                    >
                        Save settings
                    </Button>
                </div>
            </div>

        </div>
    );
}


function PresetsDropdown({ presets, onChoose }) {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(x => !x)}>
            <DropdownToggle caret color="light">
                Presets
            </DropdownToggle>
            <DropdownMenu>
                {presets.map((preset, presetIdx) => (
                    <DropdownItem
                        key={presetIdx}
                        onClick={() => onChoose(preset.options)}>
                        {preset.label}
                    </DropdownItem>
                ))}
                </ DropdownMenu>
        </Dropdown>
    );
}
