import { configureStore } from '@reduxjs/toolkit'
import { save, load } from "redux-localstorage-simple";
import { accessTokenSlice } from "app/storage/access-token";
// import * as SomeSlice from "./storage/some-slice";


const LOCALSTORAGE_CONFIG = {
    states: ["settings", "workspaces", "accessToken"],
    namespace: "voting-thingy",
    namespaceSeparator: ":",
};


export const store = configureStore({
    reducer: {
        accessToken: accessTokenSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(save(LOCALSTORAGE_CONFIG)),
    preloadedState: load(LOCALSTORAGE_CONFIG),
})
