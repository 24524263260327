import * as React from "react";
import PropTypes from "prop-types";
import { Progress } from "reactstrap";


export default function Histogram({ items }) {
    const maxWidth = items.map(x => x.width).reduce((a, b) => Math.max(a, b));
    return (
        <div>
            {items.map((item, idx) => (
                <div key={idx} className="my-4">
                    <Progress value={item.width} max={maxWidth} />
                    <div>
                        {item.label}
                    </div>
                </div>
            ))}
        </div>
    );
}


Histogram.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            width: PropTypes.number.isRequired,
            label: PropTypes.node.isRequired,
            color: PropTypes.string,
            outline: PropTypes.string,
        }).isRequired,
    ).isRequired,
};
