import * as React from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import { accessTokenSlice, useAccessToken } from "app/storage/access-token";
import { useDispatch } from "react-redux";
import { useQuery, useMutation, gql } from "@apollo/client";


export function LoginTrigger() {
    const [showLogin, setShowLogin] = React.useState(false);
    let accessToken = useAccessToken();

    return <div>
        <Button
            color={accessToken ? "secondary" : "primary"}
            onClick={() => setShowLogin(true)}
        >
            Log in {accessToken ? "again" : ""}
        </Button>
        <LoginModal
            isOpen={showLogin}
            toggle={() => setShowLogin(x => !x)}
            dismiss={() => setShowLogin(false)}
        />
        <div>Token: {accessToken}</div>
    </div>;
}



export function LoginButton(props) {
    const [showLogin, setShowLogin] = React.useState(false);

    return <>
        <Button
            onClick={() => setShowLogin(true)}
            {...props}
        >
            Log in
        </Button>
        {!!showLogin &&
         <LoginModal
             isOpen={showLogin}
             toggle={() => setShowLogin(x => !x)}
             dismiss={() => setShowLogin(false)}
         />}
    </>;
}


export function LogoutButton(props) {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(accessTokenSlice.actions.unsetValue());
    };

    return (
        <Button onClick={onClick} {...props}>
            Log out
        </Button>
    );
}


export function NavbarUserItem(props) {
    let accessToken = useAccessToken();
    if (accessToken) {
        return <React.Fragment>
            <UserNameLabel />
        </React.Fragment>;
    }

    // return <LoginButton {...props} />;
    return <LoginModal isOpen dismiss={null} />
}


export function LoginModal(props) {
    const { isOpen, dismiss } = props;

    const [displayName, setDisplayName] = React.useState("");
    const dispatch = useDispatch();
    const [doSignup, signupStatus] = useMutation(gql`
        mutation signup ($displayName: String!) {
            signup (displayName: $displayName) {
                ok
                token
                errorMessage
            }
        }
    `);

    const cleanDisplayName = value => value.trim();
    const validateDisplayName = value => {
        let cleanValue = cleanDisplayName(value);
        return cleanValue.length >= 1;
    };
    const isValid = validateDisplayName(displayName);

    const onFormSubmit = evt => {
        evt.preventDefault();

        if (!isValid) {
            return null;
        }

        let variables = {
            displayName: cleanDisplayName(displayName),
        };

        doSignup({ variables }).then(result => {
            if (result.data?.signup?.ok) {
                let { token } = result.data.signup;
                dispatch(accessTokenSlice.actions.setValue(token));
                dismiss();
            }
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={() => dismiss && dismiss()} autoFocus>
            <ModalHeader toggle={() => dismiss && dismiss()}>
                Welcome!
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={onFormSubmit}>
                    <FormGroup>
                        <Label for="fullName">
                            What's your name?
                        </Label>
                        <Input
                            id="fullName"
                            name="full_name"
                            value={displayName}
                            onChange={evt => setDisplayName(evt.target.value)} />
                    </FormGroup>
                    <div className="my-3">
                        No login necessary. This name will be shown to other users.
                    </div>
                    <div className="text-end">
                        <Button color="primary" onClick={onFormSubmit} disabled={!isValid}>
                            Save and continue
                        </Button>
                        {' '}
                        {dismiss && <Button onClick={() => dismiss()}>
                            Cancel
                        </Button>}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}


export function UserNameLabel() {
    const { data, loading, error } = useQuery(gql`
        query {
            user {
                id
                displayName
            }
        }
    `, {
        onCompleted: status => {
            console.log("Completed", status);
        },
    });

    if (loading) {
        return null;
    }

    if (error) {
        console.log(error);
        return "Error";
    }

    if (!data?.user?.id) {
        return <NukeCurrentToken />;
    }

    return <span>{data.user.displayName}</span>;
}


function NukeCurrentToken() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(accessTokenSlice.actions.unsetValue());
    });

    return null;
}
