import * as React from "react";
import classNames from "classnames";

import styles from "./flat-button.module.scss";


export default function FlatButton({ children, className: _className, ...props }) {

    let className = classNames(styles['flat-button'], _className);
    return (
        <button className={className} {...props}>
            {children}
        </button>
    );
}
