import * as React from "react";
import {
    Button,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import FlatButton from "app/ui/flat-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import useGetWorkspace from "./use-get-workspace";


export default function WorkspaceSettingsOptionsPage(props) {
    const { workspaceId } = useParams();
    const navigate = useNavigate();
    const dismiss = () => navigate(`/workspace/${workspaceId}`);
    const { data, error, loading, refetch } =
        useGetWorkspace(workspaceId, { fetchPolicy: "network-only" });

    if (error) {
        return <div>Error</div>;
    }

    if (loading || !data?.workspace) {
        return <div>Loading...</div>;
    }

    const onSuccess = () => refetch().then(() => dismiss());
    return <UsersSettings
        workspace={data.workspace}
        dismiss={() => navigate(-1)}
        refetch={refetch}
        {...props}
    />;
}


function UsersSettings({ workspace, dismiss, refetch }) {

    const [doRemoveUser] = useMutation(gql`
        mutation removeWorkspaceUser (
            $workspaceId: String!,
            $userId: String!,
        ) {
            removeWorkspaceUser (workspaceId: $workspaceId, userId: $userId) {
                ok
                errorMessage
            }
        }
    `);

    const removeUser = (userId) => {
        console.log("remove", userId);
        doRemoveUser({
            variables: {
                workspaceId: workspace.id,
                userId,
            }
        }).then(result => {
            if (result?.data?.updateWorkspace?.ok) {
                refetch && refetch();
            }
        });
    };

    return (
        <div>
            {workspace.users.map(user => (
                <div key={user.id} className="d-flex my-3">
                    <strong>{user.displayName}</strong>{" "}
                    {user.isSelf ? "(self)" : <FlatButton
                        onClick={() => removeUser(user.id)}
                        className="ms-1 text-muted"
                        title="Remove user from workspace"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </FlatButton>}{" "}
                </div>
            ))}
        </div>
    );
}
