import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

import ApolloProvider from "app/graphql-provider";
import AppRoutes from "./pages";
import { store } from "./store";

import "../style/index.scss";


export default function AppWrapper() {
    return (
        <ReduxProvider store={store}>
            <ApolloProvider>
                <Router>
                    <AppRoutes />
                </Router>
            </ApolloProvider>
        </ReduxProvider>
    );
}
