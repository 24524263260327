import * as React from "react";
import { Link, NavLink as RNavLink } from "react-router-dom";
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    NavbarText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckToSlot } from "@fortawesome/free-solid-svg-icons";
import { NavbarUserItem } from "app/ui/login";


export default function SiteNavbar() {
    return (
        <Navbar color="primary" dark expand="md" >

            <NavbarBrand tag={Link} to="/">
                <FontAwesomeIcon icon={faCheckToSlot} /> VotingThingy
            </NavbarBrand>

            <NavbarToggler onClick={function noRefCheck() { }} />

            <Collapse navbar>
                <Nav className="me-auto" navbar>

                    <NavItem>
                        <NavLink tag={RNavLink} to="/about">
                            About
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink tag={RNavLink} to="/settings/">
                            Settings
                        </NavLink>
                    </NavItem>

                </Nav>
                <NavbarText>
                    <NavbarUserItem />
                </NavbarText>
            </Collapse>
        </Navbar>
    );
}
