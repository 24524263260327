import * as React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import SiteNavbar from "./site-navbar";
import ErrorBoundary from "./error-boundary";


export default function Layout({ children, fluid }) {
    return (
        <div>
            <SiteNavbar />
            <Container className="my-3" fluid={fluid}>
                <ErrorBoundary>
                    {children}
                </ErrorBoundary>
            </Container>
        </div>
    )
}


Layout.defaultProps = {
    fluid: false,
};


Layout.propTypes = {
    fluid: PropTypes.bool,
};
