import * as React from "react";
import Layout from "app/ui/layout";
import { Navigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useMutation, gql } from "@apollo/client";


export default function Homepage() {
    return (
        <Layout>
            <div className="text-center my-5">
                <CreateWorkspaceButton />
            </div>
        </Layout>
    );
}


function CreateWorkspaceButton() {

    const [doCreateWorkspace, { data, loading }] = useMutation(gql`
        mutation createWorkspace {
            createWorkspace {
                ok
                workspaceId
                errorMessage
            }
        }
    `);

    if (data?.createWorkspace?.ok) {
        let { workspaceId } = data.createWorkspace;
        return <Navigate to={`/workspace/${workspaceId}/settings`} />;
    }

    // TODO: handle errors

    const onClick = () => {
        doCreateWorkspace();
    };

    return (
        <Button color="primary" onClick={onClick} disabled={loading} size="lg">
            Create workspace
        </Button>
    );
}
