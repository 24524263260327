import { split, HttpLink } from '@apollo/client';
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient as createWebsocketClient } from 'graphql-ws';


function replaceLocalhost(url) {
    // ***************************************************************
    // If the API_URL environment variable is pointing to localhost,
    // replace ``localhost`` with the current domain.
    // This allows development installations to be accessible from
    // multiple locations, without having to reconfigure API_URL for
    // each domain.
    // ***************************************************************
    // TODO: should we use some other domain instead of localhost?

    let { protocol, hostname } = document.location;
    return url.replace("http://localhost", `${protocol}//${hostname}`);
}


function getSubscriptionURL(url) {
    let u = new URL(url);
    u.protocol = "ws:";
    return u.toString();
}


export const API_URL = replaceLocalhost(
    process.env.REACT_APP_API_URL || "http://localhost:8000/graphql");


const typePolicies = {
    // Foobar: {
    //     fields: {
    //         // Allow partial updates from subscription
    //         foobarFoo: { merge: _mergeNotNull },
    //         foobarBar: { merge: _mergeNotNull },
    //     }
    // }
};


export function createApolloClient({ accessToken }) {
    let headers = {};
    let connectionParams = {};

    if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
        connectionParams.authToken = accessToken;
    }

    let httpLink = new HttpLink({
        uri: API_URL,
        headers,
    });

    let wsLink = new GraphQLWsLink(createWebsocketClient({
        url: getSubscriptionURL(API_URL),
        connectionParams,
    }));

    let splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    return new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache({
            typePolicies,
        }),
    });
}
