import * as React from "react";
import Layout from "app/ui/layout";
import { useQuery, useMutation, gql } from "@apollo/client";
import { LoginButton, LogoutButton } from "app/ui/login";
import { Button } from "reactstrap";


export default function SettingsPage() {
    return (
        <Layout>
            <h1>Settings</h1>
            <SettingsUIWrapper />
        </Layout>
    );
}


function SettingsUIWrapper() {
    const { data, loading, error } = useQuery(gql`
        query {
            user {
                id
                displayName
            }
        }
    `);

    if (loading || !data) {
        return null;
    }

    if (data?.user?.id) {
        return <UserSettingsUI user={data.user} />;
    }

    return <AnonymousSettingsUI />;
}


function UserSettingsUI({ user }) {
    return (
        <div>
            <div>
                <span className="text-muted">User ID:</span>{" "}
                <code>{user.id}</code>
            </div>
            <div>
                <span className="text-muted">Display name:</span>{" "}
                {user.displayName}{" "}
                <Button color="link">Edit</Button>
            </div>
            <div className="mt-5">
                <LogoutButton />
            </div>
        </div>
    );
}


function AnonymousSettingsUI() {
}
