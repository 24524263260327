import * as React from "react";
import Layout from "app/ui/layout";


const REPO_URL = "https://github.com/rshk/voting-thingy";


export default function AboutPage() {
    return (
        <Layout>
            <h1>About VotingThingy</h1>
            <p>
                Simple application allowing collaborative voting on various items.
            </p>
            <h2>Open source</h2>
            Find the <a href={REPO_URL}>source code on GitHub</a>.
        </Layout>
    );
}
