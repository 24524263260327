import * as React from "react";
import { Button } from "reactstrap";


export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    // componentDidCatch(error, errorInfo) {
    //     // You can also log the error to an error reporting service
    //     logErrorToMyService(error, errorInfo);
    // }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }

        return this.props.children;
    }
}


function ErrorPage() {
    return (
        <div className="text-center my-5">
            <h1>Something went wrong.</h1>
            <div className="my-4">
                <Button color="secondary" onClick={() => window.location.reload()}>
                    Reload page
                </Button>
            </div>
        </div>
    );
}
