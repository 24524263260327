import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";


const initialState = null;

export const accessTokenSlice = createSlice({
    name: "accessToken",
    initialState,
    reducers: {

        setValue: {
            prepare: value => ({ payload: value }),
            reducer: (state, action) => action.payload,
        },

        unsetValue: {
            reducer: (state, action) => null,
        },

    },
})


export function useAccessToken() {
    return useSelector(state => state.accessToken);
}
