import * as React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "./home";
import WorkspacePage from "./workspace";
import PageNotFound from "./404";
import AboutPage from "./about";
import SettingsPage from "./settings";

import WorkspaceSettingsPage from "./workspace/workspace-settings-modal";
import WorkspaceSettingsGeneralPage from "./workspace/workspace-settings-general";
import WorkspaceSettingsOptionsPage from "./workspace/workspace-settings-options";
import WorkspaceSettingsItemsPage from "./workspace/workspace-settings-items";
import WorkspaceSettingsUsersPage from "./workspace/workspace-settings-users";


export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/workspace/:workspaceId" element={<WorkspacePage />}>
                <Route path="settings" element={<WorkspaceSettingsPage />}>
                    <Route path="" element={<WorkspaceSettingsGeneralPage />} />
                    <Route path="items" element={<WorkspaceSettingsItemsPage />} />
                    <Route path="options" element={<WorkspaceSettingsOptionsPage />} />
                    <Route path="users" element={<WorkspaceSettingsUsersPage />} />
                </Route>
            </Route>
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/about" element={<AboutPage />} />
        </Routes>
    );
}
