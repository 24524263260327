import * as React from "react";
import { useParams } from "react-router-dom";
import { Button, Input, Table } from "reactstrap";
import { useMutation, gql } from "@apollo/client";
import useGetWorkspace from "./use-get-workspace";


const DEFAULT_NEW_ITEM = {
    name: "",
    description: "",
    status: "FUTURE",
};


export default function WorkspaceSettingsItemsPage() {
    const { workspaceId } = useParams();
    const { data, error, loading, refetch } =
        useGetWorkspace(workspaceId, { fetchPolicy: "network-only" });

    if (error) {
        return <div>Error</div>;
    }

    if (loading || !data?.workspace) {
        return <div>Loading...</div>;
    }

    const onSuccess = () => refetch();
    return <WorkspaceItemsSettingsForm
        workspace={data.workspace}
        onSuccess={onSuccess} />;
}


function WorkspaceItemsSettingsForm({ workspace, onSuccess }) {
    const [votingItems, setVotingItems] =
        React.useState((workspace.votingItems || []).map(item => ({
            id: item.id,
            name: item.name,
            description: item.description,
            status: item.status,
        })));

    const [doUpdateWorkspace] = useMutation(gql`
        mutation updateWorkspace (
            $workspaceId: String!,
            $data: UpdateWorkspaceInput!
        ) {
            updateWorkspace (workspaceId: $workspaceId, data: $data) {
                ok
                errorMessage
            }
        }
    `);

    const submit = () => {
        doUpdateWorkspace({
            variables: {
                workspaceId: workspace.id,
                data: {
                    items: votingItems
                        .filter(x => !!(x.name))
                        .map(({ id, name, description, status }) =>
                            ({ id, name, description, status }))
                },
            }
        }).then(result => {
            if (result?.data?.updateWorkspace?.ok) {
                onSuccess();
            }
        });
    };

    const onFormSubmit = () => {
        submit();
    };

    return (
        <div>

            {votingItems.length === 0
            ? <EmptyItemsSection />
            : <VotingItemsTable
                  votingItems={votingItems}
                  setVotingItems={setVotingItems}
            />}

            <div className="mt-4 d-flex justify-content-between">
                <div>
                    <Button
                        color="success"
                        onClick={() => setVotingItems(items => [...items, DEFAULT_NEW_ITEM])}
                    >
                        Add item
                    </Button>
                </div>
                <div>
                    <Button onClick={() => onFormSubmit()} color="primary">Save settings</Button>
                </div>
            </div>

        </div>
    );
}


function EmptyItemsSection() {
    return (
        <div className="text-center text-muted my-5">
            No items yet.
        </div>
    );
}


function VotingItemsTable({ votingItems, setVotingItems }) {

    const updateVotingItem = (itemIdx, updates) =>
        setVotingItems(items => [
            ...items.slice(0, itemIdx),
            {
                ...items[itemIdx],
                ...updates,
            },
            ...items.slice(itemIdx + 1),
        ]);

    const deleteVotingItem = (itemIdx) =>
        setVotingItems(items => [
            ...items.slice(0, itemIdx),
            ...items.slice(itemIdx + 1),
        ]);

    return (
        <Table borderless>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {votingItems.map((item, itemIdx) => (
                    <VotingItemsTableRow
                        key={itemIdx}
                        item={item}
                        updateItem={value => updateVotingItem(itemIdx, value)}
                        deleteItem={() => deleteVotingItem(itemIdx)}
                    />
                ))}
            </tbody>
        </Table>
    );
}


function VotingItemsTableRow({ item, updateItem, deleteItem }) {
    return (
        <tr>
            <td>
                <Input
                    value={item.name || ""}
                    onChange={event => updateItem({ name: event.target.value })}
                />
            </td>
            <td>
                <Input
                    value={item.description || ""}
                    onChange={event => updateItem({ description: event.target.value })}
                />
            </td>
            <td>
                <Button onClick={() => deleteItem()}>
                    delete
                </Button>
            </td>
        </tr>
    );
}
