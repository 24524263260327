import * as React from "react";
import Layout from "app/ui/layout";
import { Link, useParams, Outlet } from "react-router-dom";
import {
    Alert,
    Badge,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { useMutation, gql } from "@apollo/client";

import Histogram from "app/ui/histogram";
import { formatPercent, formatNumber } from "app/lib/formatting";

import useGetWorkspace from "./use-get-workspace";
import JoinWorkspaceSection from "./join-workspace-section";
import WorkspaceMenu from "./workspace-menu";
import AppendItemButton from "./append-item-button";

const ENABLE_POLLING = true;
const POLLING_INTERVAL = 1000;
const SHOW_STATUS_WHEN_CLOSED = false;


export default function Workspace() {
    const { workspaceId } = useParams();
    return (
        <Layout>
            <WorkspacePage workspaceId={workspaceId} />
            <Outlet />
        </Layout>
    );
}


function WorkspacePage({ workspaceId }) {
    const { data, loading, error, refetch, startPolling } =
        useGetWorkspace(workspaceId);

    React.useEffect(() => {
        if (ENABLE_POLLING) {
            startPolling(POLLING_INTERVAL);
        }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data?.workspace) {
        return <div>Not found</div>;
    }

    const { workspace } = data;
    return <WorkspaceUI workspace={workspace} refetch={refetch} />;
}


function WorkspaceUI({ workspace, refetch }) {
    const { users, votingItems } = workspace;

    let defaultItemIdx = getDefaultVotingItemIdx(votingItems);
    const [currentItemIdx, setCurrentItemIdx] = React.useState(defaultItemIdx);

    const currentItem = votingItems.length ? votingItems[currentItemIdx] : null;

    let optionsSetupComplete = workspace.options.length > 0;
    let itemsSetupComplete = workspace.votingItems.length > 0;
    let setupComplete = optionsSetupComplete && itemsSetupComplete;

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <h1>{workspace.label}</h1>
                <div>
                    <WorkspaceMenu workspace={workspace} refetch={refetch} />
                </div>
            </div>

            <JoinWorkspaceSection
                workspace={workspace}
                refetch={refetch}
            />

            {((!setupComplete) && workspace.authz.write) && (
                <Alert color="primary">
                    <h4>Finish setting up your workspace</h4>
                    {(!optionsSetupComplete && (
                        <div className="mt-3">
                            <Button
                                color="primary"
                                tag={Link}
                                to={`/workspace/${workspace.id}/settings/options`}
                            >
                                Define voting options
                            </Button>
                        </div>
                    ))}
                    {(!itemsSetupComplete && (
                        <div className="mt-3">
                            <Button
                                color="primary"
                                tag={Link}
                                to={`/workspace/${workspace.id}/settings/items`}
                            >
                                Create voting items
                            </Button>
                        </div>
                    ))}
                </Alert>
            )}

            {currentItem
                ? <VotingUI
                    workspace={workspace}
                    users={users}
                    votingItems={votingItems}
                    currentItem={currentItem}
                    currentItemIdx={currentItemIdx}
                    setCurrentItemIdx={setCurrentItemIdx}
                    refetch={refetch}
                />
                : <EmptyVotingUI
                    workspace={workspace}
                    refetch={refetch}
                />}

        </div>
    );
}


function EmptyVotingUI({ workspace, refetch }) {
    return (
        <div className="text-center my-5">
            <div className="text-center">
                <AppendItemButton
                    workspaceId={workspace.id}
                    onSuccess={() => {
                        refetch();
                    }}
                    text="Create a voting item"
                    outline={false}
                    color="success"
                />
            </div>
        </div>
    );
}


function VotingUI({
    workspace,
    users,
    votingItems,
    currentItem,
    currentItemIdx,
    setCurrentItemIdx,
    refetch,
}) {
    return (
        <>
            <div className="m-3 text-center">
                <VotingItemPicker
                    items={votingItems}
                    value={currentItemIdx}
                    onChange={setCurrentItemIdx}
                />
            </div>

            <div className="my-5">
                <div className="text-center my-5">
                    <h2 className="my-3">
                        {currentItem.name}{" "}
                    </h2>
                    {!!currentItem.description && (
                        <div className="my-3">
                            {currentItem.description}
                        </div>
                    )}
                    <div className="ms-2 d-flex justify-content-center">
                        <div>
                            {workspace.authz.write
                                ? <VotingItemStatusToggle
                                    workspaceId={workspace.id}
                                    itemId={currentItem.id}
                                    status={currentItem.status}
                                    onSuccess={() => refetch()}
                                />
                                : <VotingItemStatusBadge status={currentItem.status} />}
                        </div>
                        {!!workspace.authz.write && (
                            <div className="ms-1">
                                <AppendItemButton
                                    workspaceId={workspace.id}
                                    onSuccess={() => {
                                        refetch();
                                        // TODO: switch to the new item as well?
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {currentItem.status === "VOTING" &&
                    <VotingButtons
                        workspace={workspace}
                        votingItem={currentItem}
                        onSuccess={() => refetch()}
                    />}

            </div>

            <div className="my-3">
                <VotingItemUsers
                    workspace={workspace}
                    users={users}
                    votingItem={currentItem} />
            </div>

            <VotingStatusSummary
                workspace={workspace}
                users={users}
                votingItem={currentItem}
                onSuccess={() => refetch()}
            />

            <VotingResults
                workspace={workspace}
                users={users}
                votingItem={currentItem} />
        </>
    );
}


function VotingItemPicker({ items, value, onChange }) {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(x => !x)}>
            <DropdownToggle caret color="link">
                Select item to vote
            </DropdownToggle>
            <DropdownMenu>
                {items.map(({ id, name, status }, idx) => (
                    <DropdownItem
                        key={id}
                        onClick={() => onChange(idx)}
                        active={value === idx}
                    >
                        {(() => {

                            if (status === "CLOSED") {
                                return <span>
                                    {name} <Badge color="danger">closed</Badge>
                                </span>
                            }

                            if (status === "VOTING") {
                                return <span>
                                    {name} <Badge color="success">voting</Badge>
                                </span>
                            }

                            if (status === "FUTURE") {
                                if (value === idx) {
                                    return <span>{name}</span>;
                                }
                                return <span className="text-muted">{name}</span>;
                            }

                            return `${name} (${status})`;

                        })()}

                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}


function getDefaultVotingItemIdx(votingItems) {
    let foundIdx = votingItems.findIndex(x => x.status === "VOTING");
    if (foundIdx >= 0) {
        return foundIdx;
    }
    return 0; // No items currently voting
}


function VotingButtons({ votingItem, workspace, onSuccess }) {

    const [doCastVote] = useMutation(gql`
        mutation castVote (
            $workspaceId: String!,
            $itemId: String!,
            $value: String!
        ) {
            castVote (
                workspaceId: $workspaceId,
                itemId: $itemId,
                value: $value,
            ) {
                ok
                errorMessage
            }
        }
    `);

    const [doRemoveVote] = useMutation(gql`
        mutation removeVote (
            $workspaceId: String!,
            $itemId: String!
        ) {
            removeVote (
                workspaceId: $workspaceId,
                itemId: $itemId
            ) {
                ok
                errorMessage
            }
        }
    `);

    const onCastVote = (value) => {
        doCastVote({
            variables: {
                workspaceId: workspace.id,
                itemId: votingItem.id,
                value,
            },
        }).then(({ data }) => {
            if (data?.castVote?.ok) {
                if (onSuccess) {
                    onSuccess();
                }
            }
        })
    };

    const onRemoveVote = () => {
        doRemoveVote({
            variables: {
                workspaceId: workspace.id,
                itemId: votingItem.id,
            },
        }).then(({ data }) => {
            if (data?.removeVote?.ok) {
                if (onSuccess) {
                    onSuccess();
                }
            }
        })
    };

    const onClick = (optionId) => {
        if (votingItem.ownVoteValue === optionId) {
            return onRemoveVote();
        }
        return onCastVote(optionId);
    };

    return (
        <div className="d-flex justify-content-center">
            {workspace.options.map(option => (
                <VotingButton
                    key={option.id}
                    label={option.label}
                    isCurrent={votingItem.ownVoteValue === option.id}
                    disabled={votingItem.status !== "VOTING"}
                    onClick={() => onClick(option.id)}
                />
            ))}
        </div>
    );
}


function getStringLength(text) {
    return [...text].length;  // In codepoints
}


function VotingButton({ label, isCurrent, disabled, onClick }) {
    let style = {
        width: 120,
        minHeight: 80,
    };
    let classNames = ["m-1", "text-center"];
    let labelLength = getStringLength(label);
    if (labelLength === 1) {
        classNames.push("fs-1");
    }
    else if (labelLength <= 2) {
        classNames.push("fs-3");
    }
    return (
        <Button
            color="primary"
            outline={!isCurrent}
            className={classNames.join(" ")}
            style={style}
            active={isCurrent}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </Button>
    );
}


function getVotingItemStatusProps(status) {
    if (status === "VOTING") {
        return { color: "success", label: "Currently voting" };
    }
    if (status === "CLOSED") {
        return { color: "danger", label: "Voting closed" };
    }
    if (status === "FUTURE") {
        return { color: "secondary", label: "Voting not started yet" };
    }
    return { label: status };
}


function VotingItemStatusBadge({ status }) {
    const { label, ...props } = getVotingItemStatusProps(status);
    return <Button {...props}>{label}</Button>;
}


function VotingItemStatusToggle({ status, workspaceId, itemId, onSuccess }) {
    const { label, ...props } = getVotingItemStatusProps(status);
    const [isOpen, setIsOpen] = React.useState(false);

    const [doUpdateVotingItemStatus] = useMutation(gql`
        mutation updateVotingItemStatus (
            $workspaceId: String!,
            $itemId: String!,
            $status: VotingItemStatus!
        ) {
            updateVotingItemStatus (
                workspaceId: $workspaceId,
                itemId: $itemId,
                status: $status,
            ) {
                ok
                errorMessage
            }
        }
    `);

    const onChange = status => {
        doUpdateVotingItemStatus({
            variables: {
                workspaceId,
                itemId,
                status,
            },
        }).then(({ data }) => {
            if (data?.updateVotingItemStatus?.ok) {
                if (onSuccess) {
                    onSuccess();
                }
            }
        })
    };

    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(x => !x)}>
            <DropdownToggle caret {...props}>
                {label}
            </DropdownToggle>
            <DropdownMenu end>

                {status === "FUTURE" &&
                    <DropdownItem onClick={() => onChange("VOTING")}>
                        Start voting
                    </DropdownItem>}

                {status === "VOTING" &&
                    <DropdownItem onClick={() => onChange("CLOSED")}>
                        Close voting
                    </DropdownItem>}

                {status === "CLOSED" &&
                    <DropdownItem onClick={() => onChange("VOTING")}>
                        Reopen voting
                    </DropdownItem>}

            </DropdownMenu>
        </Dropdown>
    );
}


function VotingItemUsers({ users, workspace, votingItem }) {
    return (
        <div className="d-flex flex-wrap justify-content-center">
            {users.map(user => (
                <VotingItemUserItem
                    key={user.id}
                    user={user}
                    workspace={workspace}
                    votingItem={votingItem}
                />
            ))}
        </div>
    );
}


function VotingItemUserItem({ user, workspace, votingItem }) {
    const voted = votingItem.voteCast[user.id];

    let className = [
        "text-center border p-3 m-3 d-flex flex-column justify-content-center",
    ];
    if (voted) {
        className.push("border-success");
    }
    if (user.isSelf) {
        className.push("bg-success bg-opacity-10");
    }

    function renderValue() {
        if (!voted) {
            return <Badge color="danger">pending</Badge>;
        }
        let voteValue = votingItem.voteValue[user.id];
        if (voteValue) {
            let currentVote = workspace.options.find(x => x.id === voteValue);
            if (currentVote) {
                return currentVote.label;
            }
            return voteValue;
        }
        return <span className="text-muted">Voted</span>;
    }

    return (
        <div
            className={className.join(" ")}
            style={{ minWidth: 160, minHeight: 100 }}
        >

            <div className="fs-4">{user.displayName}</div>
            <div>{renderValue()}</div>
        </div>
    );
}


function VotingStatusSummary({ users, votingItem, workspace, onSuccess }) {

    const [doUpdateVotingItemStatus] = useMutation(gql`
        mutation updateVotingItemStatus (
            $workspaceId: String!,
            $itemId: String!,
            $status: VotingItemStatus!
        ) {
            updateVotingItemStatus (
                workspaceId: $workspaceId,
                itemId: $itemId,
                status: $status,
            ) {
                ok
                errorMessage
            }
        }
    `);

    const changeStatus = status => {
        doUpdateVotingItemStatus({
            variables: {
                workspaceId: workspace.id,
                itemId: votingItem.id,
                status,
            },
        }).then(({ data }) => {
            if (data?.updateVotingItemStatus?.ok) {
                if (onSuccess) {
                    onSuccess();
                }
            }
        })
    };

    let eligibleCount = users.length;
    let votedCount = 0;
    users.forEach(user => {
        if (votingItem.voteCast[user.id]) {
            votedCount += 1;
        }
    })

    if (votingItem.status === "FUTURE") {
        return (
            <Alert color="secondary">
                <div className="d-flex align-items-center justify-content-between">
                    <div>Voting session not started yet</div>
                    <div>
                        {workspace.authz.write &&
                            <Button color="success" onClick={() => changeStatus("VOTING")}>
                                Start voting session
                            </Button>}
                    </div>
                </div>
            </Alert>
        );
    }
    if (votingItem.status === "CLOSED") {
        if (!SHOW_STATUS_WHEN_CLOSED) {
            return null;
        }
        return (
            <Alert color="secondary">
                <div className="d-flex align-items-center justify-content-between">
                    <div>Voting session ended ({votedCount}/{eligibleCount} voted)</div>
                </div>
            </Alert>
        );
    }
    if (votedCount === 0) {
        return (
            <Alert color="secondary">
                <div className="d-flex align-items-center justify-content-between">
                    <div>No vote cast yet</div>
                    <div></div>
                </div>
            </Alert>
        );
    }
    if (votedCount >= eligibleCount) {
        return (
            <Alert color="success">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="fs-3">
                        Everyone voted!
                    </div>
                    <div>
                        {workspace.authz.write &&
                            <Button color="success" onClick={() => changeStatus("CLOSED")}>
                                Finish voting session
                            </Button>}
                    </div>
                </div>
            </Alert>
        );
    }
    return (
        <Alert color="warning">
            <div className="d-flex align-items-center justify-content-between">
                <div>Voting in progress ({votedCount}/{eligibleCount} voted)</div>
                <div>
                    {workspace.authz.write &&
                        <Button color="warning" onClick={() => changeStatus("CLOSED")}>
                            End voting session
                        </Button>}
                </div>
            </div>
        </Alert>
    );
}


function VotingResults({ workspace, votingItem, users }) {
    const { resultsTally } = votingItem;

    if (!(resultsTally && resultsTally.length)) {
        return null;
    }

    const itemLabels = Object.fromEntries(
        workspace.options.map(({ id, label }) => [id, label])
    );

    // Show tally of results

    const votersCount = users
        .map(x => votingItem.voteCast[x.id] ? 1 : 0)
        .reduce((a, b) => a + b);

    let items = votingItem.resultsTally.map(({ value, count }, idx) => ({
        width: count,
        label: <VotingResultLabel
            value={itemLabels[value] || value}
            aside={`${count} votes`}
            comment={`${formatPercent(count / votersCount)} of voters`}
        />,
        color: "primary",
    }));

    // Count how many did not vote

    const eligibleCount = users.length;
    const dnvCount = eligibleCount - votersCount;

    if (dnvCount > 0) {
        items.push({
            width: dnvCount,
            label: <VotingResultLabel
                value="Did not vote"
                aside={`${dnvCount}`}
                comment={`${formatPercent(dnvCount / eligibleCount)} of eligible`}
            />,
            color: "light",
            outline: "secondary",
        });
    }

    return (
        <div className="my-5 mx-auto">
            <h3>Results</h3>
            <Histogram items={items} />
            <VotingNumericResults
                workspace={workspace}
                votingItem={votingItem}
                users={users}
            />
        </div>
    );
}


function VotingResultLabel({ value, aside, comment }) {
    return (
        <div className="d-flex justify-content-between">
            <div>{value}</div>
            <div>
                {aside}{" "}
                <span className="text-muted" style={{ fontSize: '.9em' }}>
                    ({comment})
                </span>
            </div>
        </div>
    );
}


function VotingNumericResults({ workspace, votingItem, users }) {
    let votes = users
        .filter(x => votingItem.voteCast[x.id])
        .map(x => votingItem.voteValue[x.id]);
    let numericVotes = votes.filter(x => /^\d+(\.\d+)?$/.test(x)).map(x => parseInt(x, 10));

    if (!numericVotes.length) {
        return null;
    }

    let total = numericVotes.reduce((a, b) => a + b);
    let average = total / numericVotes.length;
    return (
        <div>
            Average: {formatNumber(average, { maximumFractionDigits: 1 })}
        </div>
    );

}
