import { useQuery, gql } from "@apollo/client";


export default function useGetWorkspace(workspaceId, options) {
    return useQuery(gql`
        query ($workspaceId: String!) {
            workspace (workspaceId: $workspaceId) {
                id
                label
                ownerId
                options {
                    id
                    label
                }
                users {
                    id
                    displayName
                    isSelf
                }
                votingItems {
                    id
                    name
                    description
                    status
                    voteCast
                    voteValue
                    ownVoteValue
                    resultsTally {
                        value
                        count
                    }
                }
                authz {
                    read
                    write
                    join
                    leave
                    vote
                }
                rel {
                    isAdmin
                    isMember
                }
            }
        }
    `, {
        variables: { workspaceId },
        ...options,
    });
}
